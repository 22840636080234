import { asyncComponent } from '@jaredpalmer/after';
import { LIST_COURSES_URL_CODE_BY_DEGREE, request } from '@ampli/services';
import { graphqlUrl } from '../config';

export default [
  {
    path: '/',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Home" */ '../pages/Home'),
      chunkName: 'Home',
    }),
  },
  {
    path: '/cursos',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(/* webpackChunkName: "Courses" */ '../pages/Courses'),
      chunkName: 'Courses',
    }),
  },
  {
    path: '/graduacao',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(/* webpackChunkName: "CourseList" */ '../pages/CourseList'),
      chunkName: 'CourseList',
    }),
  },
  {
    path: '/pos-graduacao',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(/* webpackChunkName: "CourseList" */ '../pages/CourseList'),
      chunkName: 'CourseList',
    }),
  },
  {
    path: '/graduacao/:urlCode',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "CourseDetailsUndergraduate" */ '../pages/CourseDetailsUndergraduate'
        ),
      chunkName: 'CourseDetailsUndergraduate',
    }),
    sitemap: {
      urlCode: () =>
        request(graphqlUrl, LIST_COURSES_URL_CODE_BY_DEGREE, {
          degree: 'UNDERGRADUATE',
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'urlCode', order: 'ASC' },
        }).then((data) => data?.list?.data.map((i) => i.urlCode)),
    },
  },
  {
    path: '/pos-graduacao/:urlCode',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "CourseDetailsGraduate" */ '../pages/CourseDetailsGraduate'
        ),
      chunkName: 'CourseDetailsGraduate',
    }),
    sitemap: {
      urlCode: () =>
        request(graphqlUrl, LIST_COURSES_URL_CODE_BY_DEGREE, {
          degree: 'GRADUATE',
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'urlCode', order: 'ASC' },
        }).then((data) => data?.list?.data.map((i) => i.urlCode)),
    },
  },
];
