import { trim, toLower, fromPairs, map } from 'lodash';
var headers;
var CRLF_REGEX = /[\r\n]+/;

var parseRawHeaders = function parseRawHeaders(raw) {
  var lines = trim(raw).split(CRLF_REGEX);
  return fromPairs(map(lines, function (line) {
    var parts = line.split(':');
    var key = parts.shift();
    var value = parts.join(':');
    return [toLower(trim(key)), trim(value)];
  }));
};

export var getAllHeaders = function getAllHeaders() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _options$location = options.location,
      location = _options$location === void 0 ? '/' : _options$location;
  if (headers) return Promise.resolve(headers);
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', location, true);
    xhr.send(null);
    xhr.addEventListener('load', function () {
      headers = parseRawHeaders(xhr.getAllResponseHeaders());
      resolve(headers);
    });
    xhr.addEventListener('error', reject);
    xhr.addEventListener('abort', reject);
  });
};
export var getHeader = function getHeader(name, options) {
  return getAllHeaders(options).then(function (headers) {
    return headers[toLower(name)];
  });
};