import localForage from 'localforage';
import { getGlobal } from "./global";
var storage = 'hipotenusa';
export var authStorage;
export var dataStorage;
export var localStorage = typeof window !== 'undefined' ? getGlobal().localStorage || {} : null;
export var initializeStorages = function initializeStorages() {
  var storeNamePrefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'generic';
  authStorage = localForage.createInstance({
    name: storage,
    storeName: "".concat(storeNamePrefix, "-auth")
  });
  dataStorage = localForage.createInstance({
    name: storage,
    storeName: "".concat(storeNamePrefix, "-data")
  });
};