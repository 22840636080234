import { loginUrl, followSubscriptionUrl } from './config';

export const STORES = {
  GOOGLEPLAY: {
    URL: 'https://play.google.com/store/apps/details?id=br.com.ampli',
  },
  APPLESTORE: {
    URL: 'https://apps.apple.com/us/app/id1503246413',
  },
};

export const CONTACT = {
  WHATSAPP: 'https://wa.me/551940422271',
};

export const HEADER_LINKS = {
  login: loginUrl,
  followSubscription: followSubscriptionUrl,
  FAQ: 'https://duvidas.ampli.com.br/',
  blog: 'https://blog.ampli.com.br/',
};

export const COURSE_DURATION_VALUES = {
  '5-meses': 5,
  '6-meses': 6,
  '7-meses': 7,
  '8-meses': 8,
  '9-meses': 9,
  '1-ano': 12,
  '1-ano-e-meio': 18,
  '2-anos': 24,
  '3-anos': 36,
};

export const COURSE_DEGREE_DURATION_TYPES = {
  UNDERGRADUATE: 'months',
  GRADUATE: 'months',
};

export const PATHNAME_BY_DEGREE_TYPE = {
  UNDERGRADUATE: { label: 'Graduação', pathname: '/graduacao' },
  GRADUATE: { label: 'Pós-graduação', pathname: '/pos-graduacao' },
};

export const COURSES_LIST_FILTERS_BY_DEGREE_TYPE = {
  UNDERGRADUATE: [
    {
      name: 'courseDuration',
      items: [
        { label: '1 ano e meio', value: '1-ano-e-meio' },
        { label: '2 anos', value: '2-anos' },
        { label: '3 anos', value: '3-anos' },
      ],
      type: 'groupButtons',
      label: 'Tempo de formação',
      extraInfo: 'em até',
    },
    {
      name: 'fieldOfStudy',
      items: [],
      type: 'checkbox',
      label: 'Área de atuação',
      wrap: true,
    },
    {
      name: 'courseType',
      items: [
        { label: 'Tecnólogo', value: 'tecnologo' },
        { label: 'Bacharelado', value: 'bacharelado' },
        { label: 'Licenciatura', value: 'licenciatura' },
      ],
      type: 'checkbox',
      label: 'Tipos de graduação',
    },
  ],
  GRADUATE: [
    {
      name: 'courseDuration',
      items: [
        { label: '5 meses', value: '5-meses' },
        { label: '6 meses', value: '6-meses' },
        { label: '7 meses', value: '7-meses' },
        { label: '8 meses', value: '8-meses' },
        { label: '9 meses', value: '9-meses' },
      ],
      type: 'groupButtons',
      label: 'Conclusão do curso',
      extraInfo: 'em até',
    },
    {
      name: 'fieldOfStudy',
      items: [],
      type: 'checkbox',
      label: 'Área de atuação',
      wrap: true,
    },
    {
      name: 'courseType',
      items: [
        { label: 'Especialização', value: 'pos-graduacao' },
        { label: 'MBA', value: 'mba' },
      ],
      type: 'checkbox',
      label: 'Tipos de pós-graduação',
    },
  ],
};

export const MIN_PRICES = {
  UNDERGRADUATE: 149.99,
  GRADUATE: 210.0,
};

export const COURSE_FILTERS_TO_URL_PARAMS = {
  name: 'nome',
  courseDuration: 'duracao',
  fieldOfStudy: 'area',
  courseType: 'tipo',
  page: 'pagina',
};

export const SCROLLER_SETTINGS = {
  isInViewportSettings: {
    modBottom: '-70%',
  },
};

export const BENEFITS_TOOLTIP_TEXTS = {
  '1º mês grátis':
    'Faça sua matrícula e o primeiro mês de aula é por nossa conta. Você não paga nada! Se você não gostar do curso, é possível cancelar a matrícula dentro desse período sem pagar nenhuma multa.',
  'Forme mais rápido':
    'Aqui permitimos que o aluno emende um módulo no outro. Dessa forma, você pode antecipar suas aulas no período de férias (janeiro, julho e dezembro) e se formar mais rápido.',
  'Valor único':
    'Nossa mensalidade é a mesma para todos os cursos, em todas as cidades. São mensalidades de valor fixo, reajustadas anualmente apenas pela inflação',
  'Aula pelo celular':
    'Tenha a praticidade de estudar através do nosso aplicativo, quando quiser, em qualquer lugar. Você ainda tem a opção de utilizar o Wi-Fi para fazer download das aulas para estudar off-line.',
};
