import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13;

import { css } from '@emotion/css';
import { memoize, isPlainObject, isArray, map, fromPairs, camelCase } from '@ampli/utils';
import { px2rem, getSpaceSize, getFontSize, getLineHeightSize, svgComponentToDataUri } from "./functions";
export var boxShadow = memoize(function (type) {
  switch (type) {
    case 'card':
      {
        return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.14);\n      "])));
      }

    case 'reverse-card':
      {
        return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n        box-shadow: 0px -4px 7px rgba(0, 0, 0, 0.14);\n      "])));
      }

    case 'highlight':
      {
        return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n        box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.14);\n      "])));
      }

    default:
      {
        return css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);\n      "])));
      }
  }
});
export var resolveCssProperty = function resolveCssProperty(property, value) {
  var resolved;

  if (isPlainObject(value)) {
    resolved = fromPairs(map(value, function (propertyValue, propertyKey) {
      return [camelCase("".concat(property, "-").concat(propertyKey)), getSpaceSize(propertyValue)];
    }));
  } else if (isArray(value)) {
    resolved = _defineProperty({}, property, map(value, function (propertyValue) {
      return getSpaceSize(propertyValue);
    }).join(' '));
  } else {
    resolved = _defineProperty({}, property, getSpaceSize(value));
  }

  return css(resolved);
};
export var iconBackground = function iconBackground(Icon, options) {
  var _ref = options || {},
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? '16px' : _ref$size,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'text-light' : _ref$color;

  var resolvedSize = px2rem(size);
  return css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    /* prettier-ignore */\n    background-image: url(\"", "\");\n    background-repeat: no-repeat;\n    background-position: center center;\n    height: ", ";\n    width: ", ";\n  "])), svgComponentToDataUri(Icon, {
    currentColor: color
  }), resolvedSize, resolvedSize);
};
export var centralized = function centralized(options) {
  var _ref2 = options || {},
      size = _ref2.size;

  var cssSize = size && "calc(-".concat(size, " / 2)");
  return css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    position: absolute;\n    top: 50%;\n    left: 50%;\n\n    ", "\n  "])), cssSize ? css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n          margin-top: ", ";\n          margin-left: ", ";\n        "])), cssSize, cssSize) : css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n          transform: translate(-50%, -50%);\n        "]))));
};
export var hideText = memoize(function () {
  return css(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    border: 0;\n    clip: rect(0, 0, 0, 0);\n    height: 1px;\n    margin: -1px;\n    overflow: hidden;\n    padding: 0;\n    position: absolute;\n    width: 1px;\n  "])));
});
export var fontSize = memoize(function () {
  var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'medium';
  return css(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    font-size: ", ";\n    line-height: ", ";\n  "])), getFontSize(size), getLineHeightSize(size));
});
export var ellipsis = memoize(function (size) {
  return css(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    width: ", ";\n  "])), size);
});
export var paddingSize = function paddingSize() {
  var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'small';
  var orientation = arguments.length > 1 ? arguments[1] : undefined;
  return css(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    padding", ": ", ";\n  "])), orientation ? "-".concat(orientation) : '', getSpaceSize(size));
};
export var marginSize = function marginSize() {
  var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'small';
  var orientation = arguments.length > 1 ? arguments[1] : undefined;
  return css(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n    margin", ": ", ";\n  "])), orientation ? "-".concat(orientation) : '', getSpaceSize(size));
};