import * as Yup from 'yup';
var requiredMsg = 'Preenchimento obrigatório.';
export var createEmailLeadSchema = function createEmailLeadSchema() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Yup.object({
    email: Yup.string().required(requiredMsg).email('E-mail inválido.')
  });
};
export var createLeadByNameSchema = function createLeadByNameSchema() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Yup.object({
    phone: Yup.string().required(requiredMsg).matches(/\(\d\d\)\s9\d{4}-\d{4}/, 'Telefone inválido.'),
    name: Yup.string().required(requiredMsg)
  });
};
export var createLeadSchema = function createLeadSchema() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Yup.object({
    name: Yup.string().notRequired().matches(/[a-z]{3,}\s([a-z]+)+/i, 'Informe seu nome e sobrenome.'),
    phone: Yup.string().required(requiredMsg).matches(/\(\d\d\)\s9\d{4}-\d{4}/, 'Telefone inválido.'),
    email: Yup.string().required(requiredMsg).email('E-mail inválido.'),
    optIn: Yup.boolean().notRequired()
  });
};
export var updateLeadSchema = function updateLeadSchema() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Yup.object({
    id: Yup.string().required(requiredMsg),
    name: Yup.string().required(requiredMsg).matches(/[a-z]{3,}\s([a-z]+)+/i, 'Informe seu nome e sobrenome.'),
    phone: Yup.string().required(requiredMsg).matches(/\(\d\d\)\s9\d{4}-\d{4}/, 'Telefone inválido.'),
    email: Yup.string().required(requiredMsg).email('E-mail inválido.'),
    optIn: Yup.boolean().notRequired(),
    courseId: Yup.string().notRequired()
  });
};