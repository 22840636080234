import { kebabCase } from '@ampli/utils';
var cityName = {
  read: function read(cached, _ref) {
    var readField = _ref.readField;
    if (cached) return cached;
    var cityRef = readField('city');
    return readField('name', cityRef);
  }
};
var stateAcronym = {
  read: function read(cached, _ref2) {
    var readField = _ref2.readField;
    if (cached) return cached;
    var stateRef = readField('state');
    return readField('acronym', stateRef);
  }
};
export default {
  keyFields: function keyFields(address) {
    return "Address:".concat(btoa(kebabCase(JSON.stringify(address))));
  },
  fields: {
    cityName: cityName,
    stateAcronym: stateAcronym
  }
};