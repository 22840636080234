import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { forEach, map } from '@ampli/utils';
import { useMutation } from '../hooks';
import { CREATE_MANY_ATTENDANCES } from './queries';
import { createManyAttendancesSchema } from './schemas';
import { SUBJECT_ENROLLMENT_FRAGMENT, LOCAL_SECTION_ENROLLMENT_FRAGMENT, LEARNING_OBJECT_COMPLETED_FRAGMENT } from '../student/queries';

var completeLearningObject = function completeLearningObject(learningObjectId, _ref) {
  var cache = _ref.cache;
  var learningObjectCacheId = cache.identify({
    __typename: 'LearningObjectEnrollment',
    id: learningObjectId
  });
  cache.writeFragment({
    id: learningObjectCacheId,
    fragment: LEARNING_OBJECT_COMPLETED_FRAGMENT,
    data: {
      completed: true
    }
  });
};

var increaseSubjectProgress = function increaseSubjectProgress(_ref2, _ref3) {
  var subjectEnrollmentId = _ref2.subjectEnrollmentId,
      sectionId = _ref2.sectionId;
  var cache = _ref3.cache;
  var sectionCacheId = cache.identify({
    __typename: 'SectionEnrollment',
    id: sectionId
  });
  var section = cache.readFragment({
    fragment: LOCAL_SECTION_ENROLLMENT_FRAGMENT,
    id: sectionCacheId
  });
  var sectionProgress = (section === null || section === void 0 ? void 0 : section.progress) || {
    completed: 0,
    quantity: 1
  };

  if (sectionProgress.completed === sectionProgress.quantity) {
    var subjectEnrollmentCacheId = cache.identify({
      __typename: 'SubjectEnrollment',
      id: subjectEnrollmentId
    });
    var subjectEnrollment = cache.readFragment({
      fragment: SUBJECT_ENROLLMENT_FRAGMENT,
      id: subjectEnrollmentCacheId
    });
    var subjectEnrollmentProgress = subjectEnrollment.progress;
    var completed = subjectEnrollmentProgress.completed + 1;
    var quantity = subjectEnrollmentProgress.quantity;
    var percentage = completed / quantity;
    var percentageLabel = "".concat(Math.round(percentage * 100), "%");
    var subjectEnrollmentProgressDetails = map(subjectEnrollment.progress.details, function (detail) {
      var completed = detail.completed;
      var percentage = detail.percentage;
      var percentageLabel = detail.percentageLabel;

      if (detail.type === 'ATTENDANCE') {
        completed += 1;
        percentage = completed / detail.quantity;
        percentageLabel = "".concat(Math.round(percentage * 100), "%");
      }

      return _objectSpread(_objectSpread({}, detail), {}, {
        completed: completed,
        percentage: percentage,
        percentageLabel: percentageLabel
      });
    });
    cache.writeFragment({
      id: subjectEnrollmentCacheId,
      fragment: SUBJECT_ENROLLMENT_FRAGMENT,
      data: _objectSpread(_objectSpread({}, subjectEnrollment), {}, {
        progress: _objectSpread(_objectSpread({}, subjectEnrollmentProgress), {}, {
          completed: completed,
          percentage: percentage,
          percentageLabel: percentageLabel,
          details: subjectEnrollmentProgressDetails
        })
      })
    });
  }
};

export var useCreateManyAttendances = function useCreateManyAttendances() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _useMutation = useMutation(CREATE_MANY_ATTENDANCES, options),
      _useMutation2 = _slicedToArray(_useMutation, 2),
      _mutate = _useMutation2[0],
      metadata = _useMutation2[1];

  var mutate = function mutate(_options) {
    var _options$variables = _options.variables,
        variables = _options$variables === void 0 ? {} : _options$variables,
        _update = _options.update,
        options = _objectWithoutProperties(_options, ["variables", "update"]);

    return _mutate(_objectSpread(_objectSpread({}, options), {}, {
      variables: variables,
      update: function update(cache, result) {
        var _result$data;

        if (result !== null && result !== void 0 && (_result$data = result.data) !== null && _result$data !== void 0 && _result$data.data && !(result !== null && result !== void 0 && result.errors)) {
          forEach(variables.data, function (_ref4) {
            var learningObjectId = _ref4.learningObjectId,
                sectionId = _ref4.sectionId,
                subjectEnrollmentId = _ref4.subjectEnrollmentId;
            completeLearningObject(learningObjectId, {
              cache: cache
            });
            increaseSubjectProgress({
              sectionId: sectionId,
              subjectEnrollmentId: subjectEnrollmentId
            }, {
              cache: cache
            });
          });
        }

        _update && _update(cache, result);
      }
    }));
  };

  return [mutate, metadata, createManyAttendancesSchema];
};