import { Interval } from '@ampli/utils';
var durationInMinutes = {
  read: function read(_, _ref) {
    var readField = _ref.readField;
    var startedDate = readField('startedDate');
    var finishedDate = readField('finishedDate');
    var interval = Interval.fromISO("".concat(startedDate, "/").concat(finishedDate));
    return parseInt(interval.length('minutes'), 10);
  }
};
export default {
  fields: {
    durationInMinutes: durationInMinutes
  }
};