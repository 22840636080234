import { useEffect } from 'react';

var useFocusWhenTrue = function useFocusWhenTrue(condition, inputRef) {
  useEffect(function () {
    if (condition && inputRef.current) {
      inputRef.current.focus();
    }
  }, [condition, inputRef.current]);
};

export default useFocusWhenTrue;