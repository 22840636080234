// Calculates the verification digits
// from: https://gist.github.com/fiuzagr/a267fcc66388e0c43912364f0df042d6
//
var digitMod = function digitMod(value, len) {
  var mod = len - Math.round(value - Math.floor(value / len) * len);
  return mod >= 10 ? 0 : mod;
};

var mapValuesWithDigitModByKey = function mapValuesWithDigitModByKey(_key, _len) {
  return function (value, key) {
    return _key === key ? digitMod(value, _len) : value;
  };
};

var reduceDigitsByKey = function reduceDigitsByKey(_key) {
  return function (digits, value, key) {
    if (key > _key) {
      digits[_key] = digits[_key] + value * (key + (_key === 0 ? 1 : 0));
      digits[key] = value;
    }

    return digits;
  };
};

var genArrayDigits = function genArrayDigits(len) {
  return new Array(len).fill(0);
};

var genRandomArrayDigits = function genRandomArrayDigits(len) {
  return genArrayDigits(len).map(function () {
    return Math.round(Math.random() * 9);
  });
};

var reduceArrayDigits = function reduceArrayDigits(digits, verifyDigits) {
  return verifyDigits.length ? reduceArrayDigits(digits.reduce(reduceDigitsByKey(verifyDigits.length - 1), genArrayDigits(verifyDigits.length)).map(mapValuesWithDigitModByKey(verifyDigits.length - 1, digits.length)), verifyDigits.slice(1)) : digits;
};

export var calculateDigits = function calculateDigits(digits, verifyDigits) {
  return reduceArrayDigits(digits.concat(verifyDigits).reverse(), verifyDigits).reverse();
};
export var generateCpf = function generateCpf() {
  return calculateDigits(genRandomArrayDigits(9), genArrayDigits(2)).join('');
};
export var validateCpf = function validateCpf(cpf) {
  return cpf && cpf.length === 11 && !/^(\d)\1{9}/.test(cpf) && calculateDigits(cpf.split('').slice(0, -2), genArrayDigits(2)).join('') === cpf;
};