var boleto = {
  read: function read(boleto, _ref) {
    var readField = _ref.readField;
    var paymentMethod = readField('paymentMethod');

    if (paymentMethod !== 'BOLETO') {
      return null;
    }

    return boleto;
  }
};
var status = {
  read: function read(status, _ref2) {
    var readField = _ref2.readField;
    var overdueDays = readField('overdueDays') || 0;

    if (status === 'OPEN' && overdueDays > 0) {
      return 'OVERDUE';
    }

    return status;
  }
};
export default {
  fields: {
    status: status,
    boleto: boleto
  }
};