import { find } from '@ampli/utils';
var type = {
  read: function read(cached, _ref) {
    var readField = _ref.readField;
    if (cached) return cached;
    var assignmentRef = readField('assignment');
    var configRef = readField('config', assignmentRef);
    return readField('type', configRef);
  }
};
var subjectName = {
  read: function read(cached, _ref2) {
    var readField = _ref2.readField;
    if (cached) return cached;
    var subjectEnrollmentRef = readField('subjectEnrollment');
    var subjectRef = readField('subject', subjectEnrollmentRef);
    return readField('name', subjectRef);
  }
};
var numberOfLearningUnits = {
  read: function read(cached, _ref3) {
    var readField = _ref3.readField;
    if (cached) return cached;
    var subjectEnrollmentRef = readField('subjectEnrollment');
    var learningUnitRefs = readField('learningUnits', subjectEnrollmentRef);
    return learningUnitRefs.length;
  }
};
var highestGradeExamId = {
  read: function read(cached, _ref4) {
    var readField = _ref4.readField;
    if (cached) return cached;
    var assignmentRef = readField('assignment');
    var attemptRefs = readField('attempts', assignmentRef);
    var highestGradeAttemptId = readField('highestGradeAttemptId', assignmentRef);
    var highestGradeAttemptRef = find(attemptRefs, function (ref) {
      return readField('id', ref) === highestGradeAttemptId;
    });
    return readField('referenceId', highestGradeAttemptRef);
  }
};
export default {
  fields: {
    type: type,
    subjectName: subjectName,
    numberOfLearningUnits: numberOfLearningUnits,
    highestGradeExamId: highestGradeExamId
  }
};