import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState, useRef } from 'react';
import { isEmpty, isString, trim, forEach } from '@ampli/utils';
import { useGetAddress } from '@ampli/services';

var useAddressForm = function useAddressForm(_ref) {
  var _ref$parentKey = _ref.parentKey,
      parentKey = _ref$parentKey === void 0 ? 'address' : _ref$parentKey,
      originValues = _ref.originValues,
      addressValues = _ref.addressValues,
      addressErrors = _ref.addressErrors,
      setFocus = _ref.setFocus,
      setFieldValue = _ref.setFieldValue,
      setFieldTouched = _ref.setFieldTouched;

  var _useState = useState({}),
      _useState2 = _slicedToArray(_useState, 2),
      addressValuesFromPostalCode = _useState2[0],
      setAddressValuesFromPostalCode = _useState2[1];

  var postalCodeFilled = addressValues.postalCode && addressValues.postalCode.replace(/[^\d]+/g, '').length === 8 && !addressErrors.postalCode;

  var _useGetAddress = useGetAddress(postalCodeFilled && addressValues.postalCode),
      getAddressLoading = _useGetAddress.loading,
      getAddressData = _useGetAddress.data;

  var getAddressDataRef = useRef(getAddressData);
  var resetFieldsRef = useRef(false);
  useEffect(function () {
    if (getAddressData && getAddressDataRef.current !== getAddressData) {
      resetFieldsRef.current = true;
      var _addressValuesFromPostalCode = {};
      forEach(getAddressData, function (value, key) {
        value = value ? isString(value) ? trim(value) : value : '';

        if (key !== 'postalCode') {
          _addressValuesFromPostalCode[key] = !isEmpty(value);
          setFieldValue("".concat(parentKey, ".").concat(key), value || '');
          setFieldTouched("".concat(parentKey, ".").concat(key), false);
        }
      });
      setAddressValuesFromPostalCode(_addressValuesFromPostalCode);
      getAddressDataRef.current = getAddressData;
      setFocus && setFocus(_addressValuesFromPostalCode);
    }
  }, [getAddressData, setFieldValue, setFieldTouched, setFocus, parentKey]);
  useEffect(function () {
    if (getAddressLoading || !postalCodeFilled) {
      forEach(addressValues, function (_, key) {
        if (!['postalCode', 'number', 'complement'].includes(key)) {
          setFieldValue("".concat(parentKey, ".").concat(key), '');
        }
      });
      setAddressValuesFromPostalCode({});
    }
  }, [getAddressLoading, postalCodeFilled, addressValues, setFieldValue, parentKey]);
  useEffect(function () {
    if (postalCodeFilled && resetFieldsRef.current) {
      resetFieldsRef.current = false;

      if (originValues.postalCode === addressValues.postalCode) {
        forEach(originValues, function (value, key) {
          if (['number', 'complement'].includes(key)) {
            setFieldValue("".concat(parentKey, ".").concat(key), value);
          }
        });
      } else {
        forEach(originValues, function (_, key) {
          if (['number', 'complement'].includes(key)) {
            setFieldValue("".concat(parentKey, ".").concat(key), '');
          }
        });
      }
    }
  }, [postalCodeFilled, originValues, addressValues, setFieldValue, parentKey]);
  return {
    filled: postalCodeFilled,
    values: addressValuesFromPostalCode,
    loading: getAddressLoading
  };
};

export default useAddressForm;