import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useMutation, useQuery } from '../hooks';
import { ANSWER_CANDIDATE_EXAM, CONFIRM_CANDIDATE_EMAIL, CREATE_CANDIDATE, CREATE_CANDIDATE_CONTRACT, EXISTS_BY_CANDIDATE_DOCUMENT, GET_CANDIDATE, GET_CANDIDATE_EXAM, GET_CANDIDATE_PAYMENT_INFO, GET_CANDIDATE_STATUS, PAY_CANDIDATE_FIRST_INSTALMENT_BY_BOLETO, PAY_CANDIDATE_FIRST_INSTALMENT_BY_CREDIT_CARD, RESET_CANDIDATE_ENEM_OPTION, SIGN_CANDIDATE_CONTRACT, UPDATE_CANDIDATE_CONTRACT_INFO, CANCEL_PENDING_PAYMENT, REDO_CANDIDATE_EXAM, VALIDATE_REDO_CANDIDATE_EXAM, COUNT_ENROLLED_CANDIDATES } from './queries';
import { answerCandidateExamSchema, confirmCandidateEmailSchema, createCandidateLegacySchema, createCandidateSchema, payCandidateFirstInstalmentSchema, signCandidateContractSchema, updateCandidateContractInfoSchema } from './schemas';
export var useExistsByCandidateDocument = function useExistsByCandidateDocument(doc) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery = useQuery(EXISTS_BY_CANDIDATE_DOCUMENT, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      document: doc
    },
    skip: !doc
  })),
      loading = _useQuery.loading,
      data = _useQuery.data,
      rest = _objectWithoutProperties(_useQuery, ["loading", "data"]);

  var exists = loading || !data ? null : data.data;
  return _objectSpread({
    data: exists,
    loading: loading
  }, rest);
};
export var useLegacyCreateCandidate = function useLegacyCreateCandidate(options) {
  return [].concat(_toConsumableArray(useMutation(CREATE_CANDIDATE, options)), [createCandidateLegacySchema]);
};
export var useCreateCandidate = function useCreateCandidate(options) {
  return [].concat(_toConsumableArray(useMutation(CREATE_CANDIDATE, options)), [createCandidateSchema]);
};
export var useConfirmCandidateEmail = function useConfirmCandidateEmail(options) {
  return [].concat(_toConsumableArray(useMutation(CONFIRM_CANDIDATE_EMAIL, options)), [confirmCandidateEmailSchema]);
};
export var useGetCandidate = function useGetCandidate(candidateId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery2 = useQuery(GET_CANDIDATE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      candidateId: candidateId
    },
    skip: !candidateId
  })),
      loading = _useQuery2.loading,
      data = _useQuery2.data;

  var candidate = loading || !data ? {} : data.data;
  return {
    candidate: candidate,
    loading: loading
  };
};
export var useGetCandidateStatus = function useGetCandidateStatus(candidateId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery3 = useQuery(GET_CANDIDATE_STATUS, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      candidateId: candidateId
    }
  })),
      loading = _useQuery3.loading,
      data = _useQuery3.data,
      rest = _objectWithoutProperties(_useQuery3, ["loading", "data"]);

  var candidate = loading || !data ? {} : data.data;
  return _objectSpread({
    data: candidate,
    loading: loading
  }, rest);
};
export var useGetCandidateExam = function useGetCandidateExam(candidateId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery4 = useQuery(GET_CANDIDATE_EXAM, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      candidateId: candidateId
    }
  })),
      loading = _useQuery4.loading,
      data = _useQuery4.data,
      rest = _objectWithoutProperties(_useQuery4, ["loading", "data"]);

  var candidate = loading || !data ? {} : data.data;
  return _objectSpread({
    data: candidate,
    loading: loading
  }, rest);
};
export var useAnswerCandidateExam = function useAnswerCandidateExam(candidateId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return [].concat(_toConsumableArray(useMutation(ANSWER_CANDIDATE_EXAM, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId: candidateId
      }
    }],
    awaitRefetchQueries: true
  }))), [answerCandidateExamSchema]);
};
export var useUpdateCandidateContractInfo = function useUpdateCandidateContractInfo(candidateId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return [].concat(_toConsumableArray(useMutation(UPDATE_CANDIDATE_CONTRACT_INFO, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId: candidateId
      }
    }],
    awaitRefetchQueries: true
  }))), [updateCandidateContractInfoSchema]);
};
export var useCreateCandidateContract = function useCreateCandidateContract(options) {
  return useMutation(CREATE_CANDIDATE_CONTRACT, options);
};
export var useSignCandidateContract = function useSignCandidateContract(candidateId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return [].concat(_toConsumableArray(useMutation(SIGN_CANDIDATE_CONTRACT, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId: candidateId
      }
    }],
    awaitRefetchQueries: true
  }))), [signCandidateContractSchema]);
};
export var useResetCandidateEnemOption = function useResetCandidateEnemOption(candidateId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useMutation(RESET_CANDIDATE_ENEM_OPTION, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId: candidateId
      }
    }],
    awaitRefetchQueries: true
  }));
};
export var usePayCandidateFirstInstalmentByBoleto = function usePayCandidateFirstInstalmentByBoleto(candidateId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return [].concat(_toConsumableArray(useMutation(PAY_CANDIDATE_FIRST_INSTALMENT_BY_BOLETO, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId: candidateId
      }
    }, {
      query: GET_CANDIDATE_PAYMENT_INFO,
      variables: {
        candidateId: candidateId
      }
    }],
    awaitRefetchQueries: true
  }))), [payCandidateFirstInstalmentSchema]);
};
export var usePayCandidateFirstInstalmentByCreditCard = function usePayCandidateFirstInstalmentByCreditCard(candidateId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return [].concat(_toConsumableArray(useMutation(PAY_CANDIDATE_FIRST_INSTALMENT_BY_CREDIT_CARD, _objectSpread(_objectSpread({
    variables: {
      candidateId: candidateId
    }
  }, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId: candidateId
      }
    }, {
      query: GET_CANDIDATE_PAYMENT_INFO,
      variables: {
        candidateId: candidateId
      }
    }],
    awaitRefetchQueries: true
  }))), [payCandidateFirstInstalmentSchema]);
};
export var useGetCandidatePaymentInfo = function useGetCandidatePaymentInfo(candidateId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery5 = useQuery(GET_CANDIDATE_PAYMENT_INFO, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      candidateId: candidateId
    }
  })),
      loading = _useQuery5.loading,
      data = _useQuery5.data,
      rest = _objectWithoutProperties(_useQuery5, ["loading", "data"]);

  var candidatePaymentInfo = loading || !data ? {} : data.data;
  return _objectSpread({
    data: candidatePaymentInfo,
    loading: loading
  }, rest);
};
export var useCancelPendingPayment = function useCancelPendingPayment(candidateId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return _toConsumableArray(useMutation(CANCEL_PENDING_PAYMENT, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId: candidateId
      }
    }, {
      query: GET_CANDIDATE_PAYMENT_INFO,
      variables: {
        candidateId: candidateId
      }
    }],
    awaitRefetchQueries: true
  })));
};
export var useRedoCandidateExam = function useRedoCandidateExam(candidateId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return _toConsumableArray(useMutation(REDO_CANDIDATE_EXAM, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId: candidateId
      }
    }],
    awaitRefetchQueries: true
  })));
};
export var useValidateRedoCandidateExam = function useValidateRedoCandidateExam() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _useQuery6 = useQuery(VALIDATE_REDO_CANDIDATE_EXAM, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [],
    awaitRefetchQueries: true
  })),
      loading = _useQuery6.loading,
      data = _useQuery6.data;

  return {
    data: loading || !data ? null : data.data,
    loading: loading
  };
};
export var useCountEnrolledCandidates = function useCountEnrolledCandidates() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _useQuery7 = useQuery(COUNT_ENROLLED_CANDIDATES, options),
      loading = _useQuery7.loading,
      data = _useQuery7.data;

  return {
    data: loading || !data ? null : data.countEnrolledCandidates,
    loading: loading
  };
};