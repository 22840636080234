import TagManager from 'react-gtm-module';
import { GTM } from "./tracking-ids";

var gtmInit = function gtmInit(context) {
  var gtmId = GTM[context];
  gtmId ? TagManager.initialize({
    gtmId: gtmId
  }) : console.error("context [".concat(context, "] is not valid"));
};

export default gtmInit;