import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useRef, useEffect, useState } from 'react';
import { useMutation as useMutationApollo } from '@apollo/client';
import useServiceClient from './use-service-client';

var useMutation = function useMutation(mutation) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var client = useServiceClient();

  var _useState = useState(0),
      _useState2 = _slicedToArray(_useState, 2),
      times = _useState2[0],
      setTimes = _useState2[1];

  var timesRef = useRef(times);

  var _onCompleted = options.onCompleted,
      onError = options.onError,
      restOptions = _objectWithoutProperties(options, ["onCompleted", "onError"]);

  var _useMutationApollo = useMutationApollo(mutation, _objectSpread(_objectSpread({
    client: client
  }, restOptions), {}, {
    onCompleted: function onCompleted(response) {
      if (response && !response.errors && response.data) {
        _onCompleted && _onCompleted(response);
      }
    }
  })),
      _useMutationApollo2 = _slicedToArray(_useMutationApollo, 2),
      _mutate = _useMutationApollo2[0],
      metadata = _useMutationApollo2[1];

  var mutate = function mutate() {
    setTimes(times + 1);
    return _mutate.apply(void 0, arguments);
  };

  useEffect(function () {
    if (timesRef.current !== times && !metadata.loading && metadata.error) {
      timesRef.current = times;
      onError && onError(metadata.error);
    }
  }, [times, timesRef, metadata.loading, metadata.error, onError]);
  return [mutate, metadata];
};

export default useMutation;