import _typeof from "@babel/runtime/helpers/typeof";
import { forEach, cloneDeepWith, some, map } from 'lodash';
export var omitDeep = function omitDeep(collection, excludeKeys) {
  var omitFn = function omitFn(value) {
    if (value && _typeof(value) === 'object') {
      forEach(excludeKeys, function (key) {
        delete value[key];
      });
    }
  };

  return cloneDeepWith(collection, omitFn);
}; // WARNING: the omitObject will be converted to a string, then the functions
// contained in the object will be lost.

export var fastOmitDeep = function fastOmitDeep(omitObject) {
  var omitProperties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return JSON.parse(JSON.stringify(omitObject, function (key, value) {
    return some(map(omitProperties, function (propertyKey) {
      return propertyKey.test ? propertyKey.test(key) : propertyKey === key;
    })) ? undefined : value;
  }));
};