import _typeof from "@babel/runtime/helpers/typeof";
export var formToJson = function formToJson(form) {
  return Object.fromEntries(new FormData(form));
};
export var objectToBlobUrl = typeof window !== 'undefined' && window.URL.createObjectURL;
export var urlToBlob = function urlToBlob(url) {
  var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onload = function () {
      var response = xhr.response;
      var fileBlob = new Blob([response], {
        type: settings.fileType || response.type
      });
      resolve(fileBlob);
    };

    xhr.onerror = reject;
    xhr.send();
  });
};
export var urlToBlobUrl = function urlToBlobUrl(url, settings) {
  return urlToBlob(url, settings).then(objectToBlobUrl);
};
export var blobToFile = function blobToFile(blob, fileName) {
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  return blob;
};
export var stringToArrayBuffer = function stringToArrayBuffer(text) {
  var buf = new ArrayBuffer(text.length);
  var bufView = new Uint8Array(buf);

  for (var index in text) {
    bufView[index] = text.charCodeAt(index);
  }

  return buf;
};
export var hexToAscii = function hexToAscii() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  for (var _i = 0, _args = args; _i < _args.length; _i++) {
    var a = _args[_i];

    if (!['number', 'string'].includes(_typeof(a))) {
      throw new Error('Invalid arguments.');
    }
  }

  var hex = args.join('').replace(/\s+/gi, '');
  var stack = [];

  for (var i = 0; i < hex.length; i += 2) {
    var code = parseInt(hex.substr(i, 2), 16);

    if (!isNaN(code) && code !== 0) {
      stack.push(String.fromCharCode(code));
    }
  }

  return stack.join('');
};
export var utf8Decoder = function utf8Decoder(arrayBuffer) {
  return new TextDecoder().decode(arrayBuffer);
};