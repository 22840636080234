import { useRef, useEffect } from 'react';
import { map, cond, constant, stubTrue, concat, get } from '@ampli/utils';

var useFocusOnError = function useFocusOnError(formik) {
  var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var submitCountRef = useRef(formik.submitCount);
  useEffect(function () {
    var firstInputErrorRef = cond(concat(map(fields, function (fieldRef, fieldName) {
      return [constant(get(formik.errors, fieldName) && get(formik.touched, fieldName)), constant(fieldRef)];
    }), [[stubTrue, constant({})]]))();

    if (firstInputErrorRef.current && formik.submitCount !== submitCountRef.current) {
      firstInputErrorRef.current.focus();
    }

    submitCountRef.current = formik.submitCount;
  }, [formik.touched, formik.error, formik.submitCount]);
};

export default useFocusOnError;