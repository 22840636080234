import { useRef } from 'react';
import useLayoutEffect from './use-layout-effect';

var useInitialFocusRef = function useInitialFocusRef() {
  var isFocusable = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  var ref = useRef();
  useLayoutEffect(function () {
    if (isFocusable && ref.current) {
      ref.current.focus();
    }
  }, [isFocusable, ref.current]);
  return ref;
};

export default useInitialFocusRef;