import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as Yup from 'yup';

var isValidRecipient = function isValidRecipient() {
  return {
    name: 'isValidRecipient',
    test: function test(value) {
      var isNumber = /^\d+$/.test(value.replace(/[^\d]+/g, ''));
      return isNumber ? Yup.string().matches(/\(\d\d\)\s9\d{4}-\d{4}/, 'Telefone inválido.') : Yup.string().email('E-mail inválido.');
    }
  };
};

export var contactPermissionSchema = Yup.lazy(function (obj) {
  return Yup.object(Object.entries(obj).reduce(function (a, _ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        key = _ref2[0];

    return _objectSpread(_objectSpread({}, a), {}, _defineProperty({}, key, Yup.object().shape({
      recipient: Yup.string().test(isValidRecipient()),
      channel: Yup.string().oneOf(['EMAIL', 'SMS', 'WHATSAPP']),
      enabled: Yup.boolean(),
      context: Yup.string().oneOf(['NOTIFICATION_CONTEXT_MARKETING', 'NOTIFICATION_CONTEXT_CRITICAL', 'NOTIFICATION_CONTEXT_INTERNAL'])
    })));
  }, {}));
});