import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useQuery, useMutation } from '../hooks';
import { FIND_CONTACT_PERMISSIONS_BY_RECIPIENT, UPDATE_CONTACT_PERMISSIONS } from './queries';
import { contactPermissionSchema } from './schemas';
export var useFindContactPermissionsByRecipient = function useFindContactPermissionsByRecipient(recipient) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery = useQuery(FIND_CONTACT_PERMISSIONS_BY_RECIPIENT, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      recipient: recipient
    },
    skip: !recipient
  })),
      loading = _useQuery.loading,
      data = _useQuery.data,
      rest = _objectWithoutProperties(_useQuery, ["loading", "data"]);

  var exists = loading || !data ? null : data.data;
  return _objectSpread({
    data: exists,
    loading: loading
  }, rest);
};
export var useUpdateManyContactPermissions = function useUpdateManyContactPermissions() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return [].concat(_toConsumableArray(useMutation(UPDATE_CONTACT_PERMISSIONS, _objectSpread({}, options))), [contactPermissionSchema]);
};