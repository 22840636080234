var readPercentage = function readPercentage(cached, _ref) {
  var readField = _ref.readField;
  if (cached) return cached;
  var completed = readField('completed') || 0;
  var quantity = readField('quantity');
  return completed / quantity;
};

var percentage = {
  read: readPercentage
};
var percentageLabel = {
  read: function read(cached, _ref2) {
    var readField = _ref2.readField;
    if (cached) return cached;
    var percentage = readPercentage(null, {
      readField: readField
    });
    return "".concat(Math.round(percentage * 100), "%");
  }
};
export default {
  fields: {
    percentage: percentage,
    percentageLabel: percentageLabel
  }
};