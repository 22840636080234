export var defaultTheme = 'undergraduate';
var selectedTheme = defaultTheme;
export var getTheme = function getTheme() {
  return selectedTheme;
};

var changeTheme = function changeTheme(theme) {
  return selectedTheme = (theme || defaultTheme).toLowerCase();
};

export default changeTheme;