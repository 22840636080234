import { useContext } from 'react';
import { ServiceContext } from '../service-provider';

var useServiceClient = function useServiceClient(client) {
  var context = useContext(ServiceContext);

  if (client === 'all') {
    return context;
  }

  return context[client || context.defaultClient];
};

export default useServiceClient;