import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useQuery } from '../hooks';
import { GET_VALID_COUPON } from './queries';
export var useGetValidCoupon = function useGetValidCoupon(couponCode) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery = useQuery(GET_VALID_COUPON, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      couponCode: couponCode
    },
    skip: !couponCode
  })),
      loading = _useQuery.loading,
      data = _useQuery.data,
      error = _useQuery.error;

  var coupon = loading || !data ? null : data.data;
  return {
    coupon: coupon,
    loading: loading,
    error: error
  };
};