import { DateTime, Interval, find, filter, sortBy } from '@ampli/utils';
import { dateField, intervalField } from './commons';
var hasCompletedExams = {
  read: function read(_, _ref) {
    var readField = _ref.readField;
    var progressRef = readField('progress');
    var progressDetails = readField('details', progressRef);
    var examDetails = find(progressDetails, {
      type: 'EXAM'
    });

    if (examDetails && examDetails.completed === examDetails.quantity) {
      return true;
    }

    return false;
  }
};
var finishedExams = {
  read: function read(cached, _ref2) {
    var readField = _ref2.readField;
    if (cached) return cached;
    var examRefs = readField('exams') || [];
    return sortBy(filter(examRefs, function (ref) {
      return readField('status', ref) === 'FINISHED';
    }), function (ref) {
      return DateTime.fromISO(readField('finishedDate', ref));
    });
  }
};

var readAssignmentConfigField = function readAssignmentConfigField(field, comparator) {
  var defaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

  var _ref3 = arguments.length > 3 ? arguments[3] : undefined,
      readField = _ref3.readField;

  var gradingMethodRef = readField('gradingMethod');
  var assignmentConfigRef = find(readField('assignmentConfigs', gradingMethodRef), function (assignmentConfigRef) {
    var type = readField('type', assignmentConfigRef);
    var reference = readField('reference', assignmentConfigRef);
    return comparator({
      type: type,
      reference: reference
    });
  });
  return assignmentConfigRef ? readField(field, assignmentConfigRef) : defaultValue;
};

var subjectExamComparator = function subjectExamComparator(_ref4) {
  var type = _ref4.type,
      reference = _ref4.reference;
  return type === 'EXAM' && reference === 'SUBJECT';
};

var subjectExamWeight = {
  read: function read(_, config) {
    return readAssignmentConfigField('weight', subjectExamComparator, 0, config);
  }
};
var subjectExamDurationInMinutes = {
  read: function read(_, config) {
    return readAssignmentConfigField('durationInMinutes', subjectExamComparator, 0, config);
  }
};
var examStartEndInterval = {
  read: function read(cached, _ref5) {
    var readField = _ref5.readField;
    if (cached) return cached;
    var endDate = readField('endDate');
    var examAssignmentRef = readField('assignments').find(function (a) {
      var detailRef = readField('detail', a);
      var configRef = readField('config', detailRef);
      var type = readField('type', configRef);
      return type === 'EXAM';
    });
    var detailRef = readField('detail', examAssignmentRef);
    var examEndDate = readField('dueDate', detailRef);
    return examEndDate && endDate ? Interval.fromDateTimes(endDate.plus({
      days: 1
    }), DateTime.fromISO(examEndDate)) : null;
  }
};
export default {
  fields: {
    startDate: dateField,
    endDate: dateField,
    closingDate: dateField,
    startEndInterval: intervalField,
    hasCompletedExams: hasCompletedExams,
    subjectExamWeight: subjectExamWeight,
    subjectExamDurationInMinutes: subjectExamDurationInMinutes,
    finishedExams: finishedExams,
    examStartEndInterval: examStartEndInterval
  }
};