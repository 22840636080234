import FakeXhr from 'fake-xml-http-request';
import { stringToArrayBuffer } from "./conversions";
Object.defineProperty(FakeXhr.prototype, 'response', {
  set: function set(response) {
    return response;
  },
  get: function get() {
    if (this.responseType === 'arraybuffer') {
      return stringToArrayBuffer(this.responseText);
    }

    return this.responseText;
  }
});
export default FakeXhr;