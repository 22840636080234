import { Interval, DateTime, fromPairs, map, kebabCase, truncate } from '@ampli/utils';

var imagesUrlFromFormats = function imagesUrlFromFormats(formats, baseUrl) {
  return fromPairs(map(formats, function (format) {
    return [format, "".concat(baseUrl, "/").concat(format)];
  }));
};

export var imageField = {
  read: function read(cached, _ref) {
    var readField = _ref.readField;
    if (cached) return cached;
    var imageRef = readField('image');
    var formats = readField('formats', imageRef);
    var baseUrl = readField('baseUrl', imageRef);
    return imagesUrlFromFormats(formats, baseUrl);
  }
};
export var pathUrlField = {
  read: function read(cached, _ref2) {
    var readField = _ref2.readField;
    if (cached) return cached;
    var title = readField('title');
    var name = readField('name');
    var id = readField('id');
    return kebabCase(truncate(title || name)) + '-' + id.slice(0, 5);
  }
};
export var dateField = {
  read: function read(date) {
    return date ? DateTime.fromISO(date) : null;
  }
};
export var intervalField = {
  read: function read(cached, _ref3) {
    var readField = _ref3.readField;
    if (cached) return cached;
    var startDate = readField('startDate');
    var endDate = readField('endDate');
    return startDate && endDate ? Interval.fromDateTimes(startDate, endDate) : null;
  }
};