import { isString } from '@ampli/utils';
export { name, version } from '../../package.json';

export const ampli =
  (process.env.AMPLI_ENV &&
    require(`@ampli/config/src/${process.env.AMPLI_ENV}`)) ||
  (typeof window !== 'undefined' && window.__AMPLI__.ampli) ||
  JSON.parse(process.env.REACT_APP_AMPLI).ampli;

export const squad =
  process.env.SQUAD_NAME ||
  (typeof window !== 'undefined' && window.__AMPLI__.squad);

export const baseUrl = ampli.baseUrl[squad] || ampli.baseUrl;
export const graphqlUrl = ampli.graphqlUrl[squad] || ampli.graphqlUrl;
export const loginUrl = ampli.loginUrl[squad] || ampli.loginUrl;
export const followSubscriptionUrl =
  ampli.followSubscriptionUrl[squad] || ampli.followSubscriptionUrl;
export const entranceExamUrl =
  ampli.entranceExamUrl[squad] || ampli.entranceExamUrl;

if (!isString(graphqlUrl)) {
  console.debug({ ampli, graphqlUrl });
  throw new Error('GraphQL URL must be a string.');
}

export const isProduction = ampli.env === 'production';

export const logLevel = isProduction ? 'error' : 'debug';

export const sentryDsn =
  'https://da9f11dd175e46b3ae53832cbf78c06b@sentry.io/5437668';

export const googleAPIKey = 'AIzaSyCNseeK7DRB1QeBDoxfcxkBfW8BsVnCjRQ';

export const recaptchaSiteKey = '6Ldb0cYUAAAAAN-q85BZljFbiyBdwRGOZRhSdQUM';
