import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

var _getGlobal$navigator, _permissions$query;

import { useEffect, useState } from 'react';
import { getGlobal } from '@ampli/utils';
var permissions = (_getGlobal$navigator = getGlobal().navigator) === null || _getGlobal$navigator === void 0 ? void 0 : _getGlobal$navigator.permissions;
var permissionsQuery = permissions === null || permissions === void 0 ? void 0 : (_permissions$query = permissions.query) === null || _permissions$query === void 0 ? void 0 : _permissions$query.bind(permissions);

var useNavigatorPermissions = function useNavigatorPermissions(query) {
  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      hasPermission = _useState2[0],
      setHasPermission = _useState2[1];

  var _useState3 = useState(),
      _useState4 = _slicedToArray(_useState3, 2),
      error = _useState4[0],
      setError = _useState4[1];

  useEffect(function () {
    if (permissionsQuery) {
      permissionsQuery(query).then(function (permission) {
        if (permission.state === 'granted') {
          setHasPermission(true);
        } else {
          setHasPermission(false);
        }

        return query;
      }).catch(setError);
    }
  }, [query]);
  return [hasPermission, error];
};

export default useNavigatorPermissions;