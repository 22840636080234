import * as Yup from 'yup';
import { DateTime, validateCpf } from '@ampli/utils';
var requiredMsg = 'Preenchimento obrigatório.';

var isValidDocumentTest = function isValidDocumentTest() {
  return {
    name: 'isValidDocumentTest',
    message: 'CPF inválido.',
    test: function test(value) {
      var onlyNumbers = value && value.replace(/[^\d]+/g, '');
      var filled = onlyNumbers && onlyNumbers.length === 11;
      return filled && validateCpf(onlyNumbers);
    }
  };
};

var isValidDateTest = function isValidDateTest() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: 'isValidDateTest',
    message: 'Data inválida.',
    test: function test(value) {
      var _options$format = options.format,
          format = _options$format === void 0 ? 'dd/MM/yyyy' : _options$format;
      var filled = value && value.replace(/[^\d]+/g, '').length === 8;
      return filled && DateTime.fromFormat(value, format).isValid;
    }
  };
};

export var candidateCredentialsSchema = function candidateCredentialsSchema() {
  return Yup.object({
    username: Yup.string().required(requiredMsg).test(isValidDocumentTest()),
    password: Yup.string().required(requiredMsg).test(isValidDateTest())
  });
};
export var sellerCredentialsSchema = function sellerCredentialsSchema() {
  return Yup.object({
    username: Yup.string().required(requiredMsg).test(isValidDocumentTest()),
    password: Yup.string().required(requiredMsg).test(isValidDateTest())
  });
};
export var studentCredentialsSchema = function studentCredentialsSchema() {
  return Yup.object({
    username: Yup.string().required(requiredMsg).test(isValidDocumentTest()),
    password: Yup.string().required(requiredMsg)
  });
};
export var ampliCredentialsSchema = function ampliCredentialsSchema() {
  return Yup.object({
    username: Yup.string().required(requiredMsg),
    password: Yup.string().required(requiredMsg)
  });
};