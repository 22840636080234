import { pathUrlField } from './commons';
var type = {
  read: function read(cached, _ref) {
    var readField = _ref.readField;
    if (cached) return cached;
    var assetType;
    var assets = readField('assets');

    if (assets) {
      var asset = assets[0];
      assetType = readField('type', asset).replace('Asset', '').toLowerCase();
    }

    return assetType === 'image' ? 'text' : assetType;
  }
};
export default {
  fields: {
    type: type,
    pathUrl: pathUrlField
  }
};