import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

var _getGlobal$navigator, _mediaDevices$getUser;

import { useEffect, useState } from 'react';
import { getGlobal } from '@ampli/utils';
var mediaDevices = (_getGlobal$navigator = getGlobal().navigator) === null || _getGlobal$navigator === void 0 ? void 0 : _getGlobal$navigator.mediaDevices;
var getUserMedia = mediaDevices === null || mediaDevices === void 0 ? void 0 : (_mediaDevices$getUser = mediaDevices.getUserMedia) === null || _mediaDevices$getUser === void 0 ? void 0 : _mediaDevices$getUser.bind(mediaDevices);

var useUserMedia = function useUserMedia(_ref) {
  var _ref$requestAuthoriza = _ref.requestAuthorization,
      requestAuthorization = _ref$requestAuthoriza === void 0 ? false : _ref$requestAuthoriza,
      media = _objectWithoutProperties(_ref, ["requestAuthorization"]);

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      stream = _useState2[0],
      setStream = _useState2[1];

  var _useState3 = useState(),
      _useState4 = _slicedToArray(_useState3, 2),
      error = _useState4[0],
      setError = _useState4[1];

  useEffect(function () {
    if (requestAuthorization && getUserMedia && !stream && !error) {
      getUserMedia(media).then(setStream).catch(setError);
    } else if ((stream || error) && requestAuthorization === false) {
      setStream();
      setError();
    }
  }, [requestAuthorization, media, error, stream]);
  return [stream, error];
};

export default useUserMedia;