import { size, sumBy } from '@ampli/utils';
import { pathUrlField } from './commons';
var progress = {
  read: function read(_, _ref) {
    var readField = _ref.readField;
    var learningObjectRefs = readField('learningObjects');
    var quantity = size(learningObjectRefs);
    var completed = sumBy(learningObjectRefs, function (learningObjectRef) {
      return readField('completed', learningObjectRef);
    });
    var percentage = completed / quantity;
    var percentageLabel = "".concat(Math.round(percentage * 100), "%");
    return {
      quantity: quantity,
      completed: completed,
      percentage: percentage,
      percentageLabel: percentageLabel
    };
  }
};
export default {
  fields: {
    pathUrl: pathUrlField,
    progress: progress
  }
};