var cutOffScorePercentage = {
  read: function read(cached, _ref) {
    var readField = _ref.readField;
    if (cached) return cached;
    var cutOffScore = readField('cutOffScore');
    return cutOffScore / 100;
  }
};
export default {
  fields: {
    cutOffScorePercentage: cutOffScorePercentage
  }
};