import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { queryStringsToCache } from '@ampli/services';

var useSaveQueryStringsToCache = function useSaveQueryStringsToCache(keyIgnoreList) {
  var saveQueryStringsToCache = queryStringsToCache.saveQueryStringsToCache;
  var queryStringFromUrl = useLocation().search;
  useEffect(function () {
    saveQueryStringsToCache(queryStringFromUrl, keyIgnoreList);
  }, [keyIgnoreList]);
};

export default useSaveQueryStringsToCache;