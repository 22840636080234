import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ensureReady, After as _After } from '@jaredpalmer/after';
import routes from '../routes';
import { BreakpointProvider, useSaveQueryStringsToCache } from '@ampli/hooks';
import { ServiceProvider, clientCreator } from '@ampli/services';
import { initializeStorages } from '@ampli/utils';
import '@ampli/ui/dist/global-styles';

import '../root-styles';
import '../lib/recaptcha';
import { graphqlUrl } from '../config';
import { gtmInit } from '@ampli/tracking';
import { COURSE_FILTERS_TO_URL_PARAMS } from '../constants';

const QUERY_PARAMS_TO_CACHE_IGNORE_LIST = Object.values(
  COURSE_FILTERS_TO_URL_PARAMS
);
const After = (props) => {
  useSaveQueryStringsToCache(QUERY_PARAMS_TO_CACHE_IGNORE_LIST);
  return <_After {...props} />;
};

const publicClient = clientCreator({
  graphqlUrl,
  secure: false,
});
const privateClient = clientCreator({
  graphqlUrl,
  secure: true,
});

initializeStorages('course');

gtmInit('growth');

ensureReady(routes)
  .then((data) =>
    hydrate(
      <BrowserRouter>
        <ServiceProvider
          defaultClient="publicClient"
          clients={{ publicClient, privateClient }}
        >
          <BreakpointProvider>
            <After data={data} routes={routes} client={publicClient} />
          </BreakpointProvider>
        </ServiceProvider>
      </BrowserRouter>,
      document.getElementById('root')
    )
  )
  .catch((error) => console.error(error));

if (module.hot) {
  module.hot.accept();
}
