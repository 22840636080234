import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { ApolloClient, createHttpLink, from } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import fetch from 'cross-fetch';
import { onError } from '@apollo/client/link/error';
import { APIError, compact, GraphQLError, map } from '@ampli/utils';
import { setContext } from '@apollo/client/link/context';
var errorLink = onError(function (_ref) {
  var graphQLErrors = _ref.graphQLErrors,
      operation = _ref.operation,
      networkError = _ref.networkError;
  var context = operation.getContext();
  var requestHeaders = context.headers || {};
  var response = context.response || {};
  var responseStatus = response.status;
  var operationName = operation.operationName;

  if (graphQLErrors) {
    map(graphQLErrors, function (error) {
      console.error(new GraphQLError("".concat(error.code, ": ").concat(error.message, " (").concat(error.status, ")"), {
        requestHeaders: requestHeaders,
        responseStatus: responseStatus,
        operationName: operationName
      }));
    });
  }

  if (networkError) {
    console.error(new APIError(networkError.message, {
      requestHeaders: requestHeaders,
      responseStatus: responseStatus,
      operationName: operationName
    }));
  }
});
var headersLink = setContext(function (_ref2) {
  var _ref2$headers = _ref2.headers,
      headers = _ref2$headers === void 0 ? {} : _ref2$headers;
  return {
    headers: _objectSpread({
      authorization: 'Basic cG9ydGFsOnNlY3JldA=='
    }, headers)
  };
});

var ssrClientCreator = function ssrClientCreator(_ref3) {
  var graphqlUrl = _ref3.graphqlUrl;
  var link = from(compact([errorLink, headersLink, createHttpLink({
    uri: graphqlUrl,
    fetch: fetch
  })]));
  return new ApolloClient({
    ssrMode: true,
    link: link,
    cache: new InMemoryCache()
  });
};

export default ssrClientCreator;