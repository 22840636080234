import HorizontalColor from "./horizontal-color";
import HorizontalBlue from "./horizontal-blue";
import HorizontalWhite from "./horizontal-white";
import VerticalColor from "./vertical-color";
import VerticalBlue from "./vertical-blue";
import VerticalWhite from "./vertical-white";
import IconColor from "./icon-color";
import IconBlue from "./icon-blue";
import IconWhite from "./icon-white";
import TextHorizontalWhite from "./text-horizontal-white";
import SimpleColor from "./simple-color";
export var Logo = {
  HorizontalColor: HorizontalColor,
  HorizontalBlue: HorizontalBlue,
  HorizontalWhite: HorizontalWhite,
  VerticalColor: VerticalColor,
  VerticalBlue: VerticalBlue,
  VerticalWhite: VerticalWhite,
  IconColor: IconColor,
  IconBlue: IconBlue,
  IconWhite: IconWhite,
  TextHorizontalWhite: TextHorizontalWhite,
  SimpleColor: SimpleColor
};