import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { useMutation } from '../hooks';
import { CREATE_LEAD, CREATE_EMAIL_LEAD, NEW_CREATE_LEAD, UPDATE_LEAD } from './queries';
import { createEmailLeadSchema, createLeadSchema, createLeadByNameSchema // createNewLeadSchema,
, updateLeadSchema } from './schemas';
export var useCreateLead = function useCreateLead(options) {
  return [].concat(_toConsumableArray(useMutation(CREATE_LEAD, options)), [createLeadSchema]);
};
export var useCreateLeadByName = function useCreateLeadByName(options) {
  return [].concat(_toConsumableArray(useMutation(CREATE_LEAD, options)), [createLeadByNameSchema]);
};
export var useCreateEmailLead = function useCreateEmailLead(options) {
  return [].concat(_toConsumableArray(useMutation(CREATE_EMAIL_LEAD, options)), [createEmailLeadSchema]);
}; // export const useNewCreateLead = (options) => {
//   return [...useMutation(NEW_CREATE_LEAD, options), createNewLeadSchema];
// };

export var useUpdateLead = function useUpdateLead(options) {
  return [].concat(_toConsumableArray(useMutation(UPDATE_LEAD, options)), [updateLeadSchema]);
};